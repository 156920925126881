import media from "src/styles/media"
import styled from "styled-components"

export const Title1 = styled.h1`
  //60px
  font-size: 4.2vw;
  line-height: 130.5%;
  ${media.tablet} {
    font-size: 8.7vw;
  }
`
export const Title2 = styled.h2`
  //45px
  font-size: 3.1vw;
  line-height: 1.3111111111111111em;
  ${media.tablet} {
    font-size: 8.7vw;
  }
`
export const Title3 = styled.h2`
  //30px
  font-size: 2vw;
  line-height: 130.5%;
  ${media.tablet} {
    font-size: 7.2vw;
  }
`
export const Paragraph1 = styled.p`
  //18px
  font-size: 1.2vw;
  line-height: 154.5%;
  ${media.tablet} {
    font-size: 3.7vw;
  }
`
export const Paragraph2 = styled.p`
  //24px
  font-size: 1.7vw;
  line-height: 130.5%;
  ${media.tablet} {
    font-size: 4.3vw;
  }
`
export const Paragraph3 = styled.p`
  //16px
  font-size: 1.1vw;
  line-height: 130.5%;
  ${media.tablet} {
    font-size: 3.4vw;
  }
`

export const Paragraph4 = styled.p`
  //10px
  font-size: 0.7vw;
  line-height: 130.5%;
`

export const Label1 = styled.p`
  font-family: "Mondwest";
  font-size: 1.5vw;
  line-height: 130.5%;
  ${media.tablet} {
    font-size: 4.8vw;
  }
`

export const LeviPage = {
  H1: `font-family: Interstate;
font-style: normal;
font-weight: bold;
font-size: 4.5vw;
line-height: 100%;
letter-spacing: 0.011em;
text-transform: uppercase;`,
  H2: `font-family: Interstate;
font-style: normal;
font-weight: bold;
font-size: 1.2vw;
line-height: 120%;
text-transform: capitalize;`,
  H3: `font-family: Interstate;
font-style: normal;
font-weight: bold;
font-size: 1.3vw;
line-height: 175.52%;
letter-spacing: -0.040em;`,
  SubTitle1: `font-family: Interstate;
font-style: normal;
font-weight: normal;
font-size: 0.8vw;
line-height:  117%;
letter-spacing: 0.5em;
text-transform: uppercase;`,
  Body1: `font-family: Interstate;
font-style: normal;
font-weight: normal;
font-size: 1.2vw;
line-height: 120%;
letter-spacing: 0.040em;
text-transform: uppercase;
`,

  Body2: `
font-family: Interstate !important;
font-style: normal;
font-weight: normal;
font-size: 0.9vw;
line-height: 175.52%;
letter-spacing: -0.71875px;`,
  NFTTitle: `font-family: Matter SQ;
font-style: normal;
font-weight: bold;
font-size: 1.4vw;
line-height: 130%;`,
  NFTInfo: `
font-family: Matter SQ;
font-style: normal;
font-weight: bold;
font-size: 0.8vw;
line-height: 130%;
display: flex;
color: inherit;
align-items: flex-end;
letter-spacing: 0.06em;
text-transform: uppercase;`,
}
